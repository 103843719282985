export const isNative = false;
export const navbarDefaultWidth = '300px'
import { firestore } from '@/util/firebase';
import {type zohoNavItem, links} from './tmpZohoNavItems';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';

export async function getZohoNavLinks(){

    const zohoCollection = collection(firestore, "ZohoNavLinks")

    const links: zohoNavItem[] = []
    const q = query(zohoCollection, where("region", "==", "header"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        links.push(doc.data() as zohoNavItem)
    })

	return links;
}

export function routeToDestinations() {
    window.open('https://destinations.hxp.org/#/shopping-home');
}

export function logOut() {
    //Stub, this function shouldn't be called from the snippet
}