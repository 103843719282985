import {ref, computed, watch,onMounted} from 'vue';

//const MOBILE_SIZE = 1050;
const MOBILE_SIZE = 1222;

export const isMobile  = ref(false);

function checkHeaderState(){
    const windowWidth = window.innerWidth;
    if(windowWidth > MOBILE_SIZE){
        isMobile.value = false;
    }else{
        isMobile.value = true;
    }
}


watch(()=> window.innerWidth, headerHeight =>{
    checkHeaderState();
},{immediate: true});

window.addEventListener("resize",()=>{
    checkHeaderState();
});

const tmpValue = 50;
export const HEADER_HEIGHT = tmpValue;
export const MOBILE_HEIGHT = tmpValue;


export const headerHeight = computed(()=>`${isMobile.value ? MOBILE_HEIGHT : HEADER_HEIGHT}px`);